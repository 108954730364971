const body = document.querySelector('body');
const navButton = body.querySelector('.burger');
const navMenu = body.querySelector('.main-nav');
const navList = navMenu.querySelector('.nav-list');
const navLinks = navList.querySelectorAll('.nav-list__link');

const openMenu = () => {
  navLinks.forEach((elem) => {
    elem.tabIndex = 2;
  });
  navMenu.classList.add('is-menu-opened');
  navButton.tabIndex = 1;
  body.classList.add('overlay');
};

const closeMenu = () => {
  navLinks.forEach((elem) => {
    elem.tabIndex = -1;
  });
  navMenu.classList.remove('is-menu-opened');
  navButton.tabIndex = 0;
  body.classList.remove('overlay');
};

const onOverlayClick = (evt) => {
  if (evt.target === body) {
    closeMenu();
  }
};

const onMenuToggleClock = () => {
  const state = navMenu.classList.contains('is-menu-opened');

  if (!state) {
    openMenu();
    body.addEventListener('click', onOverlayClick);
  }

  if (state) {
    closeMenu();
    body.removeEventListener('click', onOverlayClick);
  }
};

const initMenu = () => {
  if (window.innerWidth < 768) {
    navLinks.forEach((elem) => {
      elem.tabIndex = -1;
    });
  }
  navButton.addEventListener('click', onMenuToggleClock);
};

export {initMenu};
