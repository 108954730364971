const createScriptTag = (src) => {
  const tag = document.createElement('script');
  tag.src = src;
  return tag;
};

const insertBefore = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode);
};

const createYouTubePlayer = (videoId, containerId) =>
  new YT.Player(containerId, {
    width: '560',
    height: '315',
    videoId,
    playerVars: {autoplay: 1},
  });

const getVideoId = (url) => {
  const regularMatch = url.match(/[?&]v=([^&]+)/);
  const shortMatch = url.match(/(?:youtu\.be\/|youtube\.com\/shorts\/)([^&/]+)/);
  return (regularMatch && regularMatch[1]) || (shortMatch && shortMatch[1]) || '';
};

const onLinkClick = (event) => {
  event.preventDefault();

  const videoUrl = event.currentTarget.getAttribute('href');
  const parentLi = event.currentTarget.closest('li');
  const iframeContainer = parentLi.querySelector('div.practics__iframe');

  pauseAllVideos();

  if (iframeContainer) {
    const videoId = getVideoId(videoUrl);
    const player = createYouTubePlayer(videoId, iframeContainer.id);
    players.push(player);
  }
  const isActive = parentLi.classList.toggle('is-active');

  const allLinks = document.querySelectorAll('[data-embedded]');
  allLinks.forEach((link) => {
    const otherParentLi = link.closest('li');

    if (otherParentLi !== parentLi && isActive) {
      otherParentLi.classList.remove('is-active');
    }
  });
};

const pauseAllVideos = () => {
  players.forEach((player) => {
    if (player && player.pauseVideo) {
      player.pauseVideo();
    }
  });
};

const initEmbeddedVideos = () => {
  const tag = createScriptTag('https://www.youtube.com/iframe_api');
  const firstScriptTag = document.getElementsByTagName('script')[0];
  insertBefore(tag, firstScriptTag);

  const videoLinks = document.querySelectorAll('[data-embedded]');
  videoLinks.forEach((el) => el.addEventListener('click', onLinkClick));
};

const players = [];

export {initEmbeddedVideos};
