const initTools = () => {
  const toolsList = document.querySelector('.tools__list');
  if (toolsList) {
    const toolsItems = toolsList.querySelectorAll('.tools__item');
    const loadMoreButton = document.querySelector('.tools__show-more');
    const data = Array.from(toolsItems);
    const dataLength = data.length;
    const LOAD_MORE_STEP = 3;

    let defaultItems = 3;
    let counter = 0;

    const initDefault = () => {
      const initData = data.slice(defaultItems, dataLength);
      initData.forEach((elem) => {
        elem.classList.add('hidden');
      });
    };

    const loadMore = () => {
      counter += LOAD_MORE_STEP;
      defaultItems += LOAD_MORE_STEP;
      const items = data.slice(counter, defaultItems);
      if (defaultItems <= dataLength) {
        items.forEach((elem, index) => {
          elem.classList.add('js-animate');
          elem.classList.remove('hidden');
          elem.style.animationDelay = `${index / 2}s`;
        });
      }

      if (defaultItems === dataLength) {
        loadMoreButton.style.display = 'none';
      }
    };

    initDefault();
    loadMoreButton.addEventListener('click', loadMore);
  }
};

export {initTools};
