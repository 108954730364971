const initVideo = () => {
  const videoCover = document.querySelector('.review__wrapper');

  if (videoCover) {
    const playButton = videoCover.querySelector('.review__button');

    const onPlayButtonClick = () => {
      videoCover.insertAdjacentHTML('beforeend', '<iframe width="560" height="315" src="https://www.youtube.com/embed/DDY6u0Na6SQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
      playButton.style.display = 'none';
    };
    playButton.addEventListener('click', onPlayButtonClick);
  }
};

export {initVideo};
